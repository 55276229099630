import React from "react";

import "./EmptyPane.scss";

/**
 * The background when there's no pane open
 */
const EmptyPane: React.FC = () => (
    <div className="empty-pane-bg">
        <div />
    </div>
);

export default EmptyPane;
